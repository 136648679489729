<template>
  <base-section
    id="map"
    space="0"
  >
    <iframe
      allowfullscreen
      aria-hidden="false"
      frameborder="0"
      height="500"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2034.3433124906426!2d18.06723571644394!3d59.34391688166394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9da0b2f600e9%3A0x4adc5bc7e95509b0!2sEthos%20Evaluate%20AB!5e0!3m2!1ssv!2sse!4v1661871395364!5m2!1ssv!2sse"
      style="border:0; display: block;"
      tabindex="0"
      width="100%"
    />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMap',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
